import { useContext, useState } from "react"
import {
  NotifcationsTableProps,
  NotifcationsData,
} from "../../../types/notificationsTypes"
import { deleteNotification } from "../../../services/notifications"
import { queryClient } from "../../../utils/reactQueryClient"
import useCustomMutation from "../../../hooks/useCustomMutation"
import DeleteButton from "../../shared/DeleteButton"
import { MdEdit } from "react-icons/md"
import { limitContent } from "../../../utils/functions"
import NotificationModal from "../modal/NotificationModal"
import { UserContext } from "../../../context/AuthContext"

const NotificationsTable: React.FC<NotifcationsTableProps> = ({
  notifications,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editingNotification, setEditingNotification] = useState<
    NotifcationsData | undefined
  >(undefined)

  const { user } = useContext(UserContext)

  const isSuperAdmin = user?.role?.role === "SUPER_ADMIN"

  const { mutate: removeNotification } = useCustomMutation({
    mutationFn: deleteNotification,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["notifications"] })
    },
  })

  const handleEdit = (notification: NotifcationsData) => {
    setEditingNotification(notification)
    setIsModalOpen(true)
  }

  const handleDelete = (NotificationId: string) => {
    removeNotification(NotificationId)
  }

  return (
    <div className="overflow-x-auto mt-6">
      <table className="min-w-full divide-y divide-gray-200 shadow-sm">
        <thead className="bg-gray-50">
          <tr>
            <th className="th-text">العنوان</th>
            <th className="th-text">تاريخ بداية الاشعار</th>
            <th className="th-text">تاريخ نهاية الاشعار</th>
            <th className="th-text">نوع الاشعار</th>
            <th className="th-text w-[30%]">رسالة الإشعار</th>
            <th className="th-text text-center">تعديل</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {notifications.length === 0 ? (
            <tr>
              <td className="td-text text-center" colSpan={7}>
                لا توجد بيانات
              </td>
            </tr>
          ) : (
            notifications.map((notification, index) => (
              <tr key={index} className="hover:bg-gray-50">
                <td className="td-text">{notification.title}</td>
                <td className="td-text">
                  {new Date(notification.startDate).toLocaleDateString("fr-EG")}
                </td>
                <td className="td-text">
                  {new Date(notification.endDate).toLocaleDateString("fr-EG")}
                </td>
                <td className="td-text">
                  {notification?.recurrenceType === "DAILY"
                    ? "يومي"
                    : notification?.recurrenceType === "WEEKLY"
                      ? "اسبوعي"
                      : notification?.recurrenceType === "MONTHLY"
                        ? "شهري"
                        : "سنوي"}
                </td>
                {/* <td className="td-text">{notification?.occurrences}</td>
                <td className="td-text">{notification?.interval}</td> */}
                <td className="px-6 py-4 text-right text-xs font-medium text-gray-900 tracking-wider w-[50%]">
                  {limitContent(notification.message)}
                </td>
                <td className="whitespace-nowrap text-left px-6 py-3">
                  <button
                    onClick={() => handleEdit(notification)}
                    className="btn-edit"
                  >
                    <MdEdit className="w-5 h-5 " />
                  </button>
                  {isSuperAdmin && (
                    <DeleteButton
                      onDelete={() => handleDelete(notification.id)}
                      itemId={notification.id}
                      itemName={notification.title}
                    />
                  )}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      {isModalOpen && (
        <NotificationModal
          onClose={() => {
            setIsModalOpen(false)
            setEditingNotification(undefined)
          }}
          notification={editingNotification}
        />
      )}
    </div>
  )
}

export default NotificationsTable
