import { useState } from "react"
import apiClient from "../../utils/api"
import classNames from "classnames"
import { isValidUrl } from "../../utils/functions"

interface FileUploadResponse {
  fileName: string
  fileType: string
}
interface FileUploadProps {
  onFileUpload: (fileUrl: FileUploadResponse) => void
  ImgUrl: any
  error: any
  setError: (err: any) => void
}

const UploadImage: React.FC<FileUploadProps> = ({
  onFileUpload,
  ImgUrl,
  error,
  setError,
}) => {
  const [uploading, setUploading] = useState(false)
  const [urlImg, setUrlImg] = useState(ImgUrl)

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const files = event.target.files
    if (files && files.length > 0) {
      const file = files[0]

      const acceptedTypes = ["image/png", "image/jpeg"]
      if (!acceptedTypes.includes(file.type)) {
        setError("نوع الملف غير صالح. الرجاء تحميل ملف PNG أو JPG.")
        return
      }

      const formData = new FormData()
      formData.append("file", file)

      setUploading(true)
      try {
        const response = await apiClient.post("/storage/uploadFile", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        const fileName = {
          fileName: response.data.fileName,
          fileType: response.data.fileType,
        }
        onFileUpload(fileName)
        setUrlImg(fileName.fileName)
      } catch (error) {
        console.error("Error uploading file:", error)
      } finally {
        setUploading(false)
      }
    }
  }

  return (
    <div className={classNames("input py-3", { "input-invalid": error })}>
      <div className="flex items-center ">
        <label className="cursor-pointer bg-gradient-to-r from-green-600 to-green-900 hover:bg-green-800 text-white py-1 px-4 rounded-lg shadow-lg">
          <span>اختر الملف</span>
          <input
            type="file"
            accept=".png,.jpg"
            onChange={handleFileChange}
            disabled={uploading}
            className="hidden"
          />
        </label>
        <div>
          {uploading && (
            <p className="text-green-600 pr-11">جاري التحميل ...</p>
          )}
        </div>
      </div>

      {urlImg ? (
        <div className="flex justify-between items-center bg-blue-200 bg-opacity-50 shadow-md rounded-xl p-2 hover:bg-opacity-100 transition-colors duration-300 mt-4">
          <p className="text-base text-blue-900">
            {urlImg.length > 20 ? urlImg.substring(0, 20) + "..." : urlImg}
          </p>
          <img
            src={
              isValidUrl(urlImg)
                ? urlImg
                : `https://islamic.peaqock.com/api/storage/downloadFile/${urlImg}`
            }
            className="w-8 h-8 text-green-500 ml-4 hover:scale-150 transition-all duration-700 cursor-pointer"
            alt="Image Cover"
          />
        </div>
      ) : (
        <div className="mt-4 bg-blue-200 bg-opacity-50 shadow-md rounded-xl p-2 hover:bg-opacity-100 transition-colors duration-300">
          <p className="text-base text-blue-900">لم يتم رفع أي صورة</p>
        </div>
      )}
    </div>
  )
}

export default UploadImage
