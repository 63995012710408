export const days = [
  {
    name: "الاثنين",
    value: "MONDAY",
  },
  {
    name: "الثلاثاء",
    value: "TUESDAY",
  },
  {
    name: "الأربعاء",
    value: "WEDNESDAY",
  },
  {
    name: "الخميس",
    value: "THURSDAY",
  },
  {
    name: "الجمعة",
    value: "FRIDAY",
  },
  {
    name: "السبت",
    value: "SATURDAY",
  },
  {
    name: "الأحد",
    value: "SUNDAY",
  },
]

export const recurrenceType = [
  {
    name: "يومي",
    value: "DAILY",
  },
  {
    name: "أسبوعي",
    value: "WEEKLY",
  },
  {
    name: "شهري",
    value: "MONTHLY",
  },
  {
    name: "سنوي",
    value: "YEARLY",
  },
]

export const lessonsType = [
  {
    name: "مرئيات",
    value: "VIDEO",
  },
  {
    name: "صوتيات",
    value: "AUDIO",
  },
]

export const roleUser = [
  {
    name: "مشرف",
    value: "ADMIN",
  },
  {
    name: "مستخدم",
    value: "USER",
  },
]

export const initialItems = [
  {
    id: "1",
    content: "التعريف بزاويتنا",
  },
  {
    id: "2",
    content: "أورادنا وأذكارنا",
  },
  {
    id: "3",
    content: "سند زاويتنا و شيوخنا",
  },
  {
    id: "4",
    content: "دروس تربوية",
  },
  {
    id: "5",
    content: "خطب الجمعة",
  },
  {
    id: "6",
    content: "برامج إعلامية",
  },
  {
    id: "7",
    content: "أنشطتنا و سياحاتنا",
  },
  {
    id: "8",
    content: "البث المباشر",
  },
]

export const colors = [
  "#000000",
  "#e60000",
  "#ff9900",
  "#ffff00",
  "#008a00",
  "#0066cc",
  "#9933ff",
  "#ffffff",
  "#facccc",
  "#ffebcc",
  "#ffffcc",
  "#cce8cc",
  "#cce0f5",
  "#ebd6ff",
  "#bbbbbb",
  "#f06666",
  "#ffc266",
  "#ffff66",
  "#66b966",
  "#66a3e0",
  "#c285ff",
  "#888888",
  "#a10000",
  "#b26b00",
  "#b2b200",
  "#006100",
  "#0047b2",
  "#6b24b2",
  "#444444",
  "#5c0000",
  "#663d00",
  "#666600",
  "#003700",
  "#002966",
  "#3d1466",
  "#ff007f",
  "#ff00ff",
  "#800080",
  "#ffa500",
  "#800000",
  "#ff69b4",
  "#00ced1",
  "#d2691e",
  "#ff1493",
  "#cd5c5c",
  "#4b0082",
  "#483d8b",
  "#2e8b57",
  "#8a2be2",
  "#7fff00",
  "#dc143c",
  "#00bfff",
  "#696969",
  "#1e90ff",
  "#00fa9a",
  "#8b0000",
  "#556b2f",
  "#9400d3",
  "#ff4500",
  "#ee82ee",
  "#5f9ea0",
  "#8fbc8f",
  "#ff6347",
  "#4682b4",
  "#f0e68c",
  "#dda0dd",
  "#2f4f4f",
  "#98fb98",
  "#ffdead",
  "#ffdab9",
  "#7cfc00",
  "#ffd700",
  "#ff69b4",
  "#8b008b",
  "#b03060",
  "#98fb98",
  "#afeeee",
  "#db7093",
  "#ff4500",
  "#f08080",
  "#b22222",
  "#00ff7f",
  "#7fffd4",
  "#6a5acd",
  "#191970",
  "#00008b",
  "#8a2be2",
  "#ffb6c1",
  "#8b4513",
  "#48d1cc",
  "#ff00ff",
  "#32cd32",
  "#8fbc8f",
  "#daa520",
  "#000080",
  "#4682b4",
  "#00ffff",
  "#bc8f8f",
  "#ffa07a",
  "#20b2aa",
  "#778899",
  "#b0c4de",
  "#00ced1",
]
