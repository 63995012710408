import { useEffect, useState } from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { MdOutlineDragIndicator } from "react-icons/md"
import { SortListHomeProps } from "../../../types/sortTypes"

const SortListHome: React.FC<SortListHomeProps> = ({
  onItemsChange,
  listinitialItems,
}) => {
  const [items, setItems] = useState(listinitialItems)

  useEffect(() => {
    onItemsChange(items)
  }, [items, onItemsChange])

  const handleOnDragEnd = (result: any) => {
    if (!result.destination) return
    const reorderedItems = Array.from(items)
    const [reorderedItem] = reorderedItems.splice(result.source.index, 1)
    reorderedItems.splice(result.destination.index, 0, reorderedItem)
    setItems(reorderedItems)
  }

  const toggleItemShow = (id: string) => {
    const updatedItems = items.map((item) =>
      item.id === id ? { ...item, show: !item.show } : item,
    )
    setItems(updatedItems)
  }

  return (
    <div className="w-full box-border">
      <h2 className="text-xl font-bold mb-4">ترتيب الصفحة الرئيسية </h2>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="items">
          {(provided) => (
            <ul
              className="bg-white p-4 rounded shadow-lg max-w-sm w-full text-right"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided) => (
                    <li
                      className="flex flex-row justify-between items-center bg-gray-200 mb-2 py-2 rounded cursor-move"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div className="flex items-center">
                        <div className="px-1 py-2 bg-gradient-to-r from-[#1E8A75] to-[#182A26] rounded-l-xl">
                          <p className="text-white font-bold">{index + 1}</p>
                        </div>
                        <MdOutlineDragIndicator className="text-gray-500 w-8 h-8" />
                        <p className="pr-4">{item.content}</p>
                      </div>
                      <input
                        type="checkbox"
                        checked={item.show}
                        onChange={() => toggleItemShow(item.id)}
                        className="form-checkbox h-5 w-5 accent-[#1E8A75] rounded focus:ring-0 transition duration-150 ease-in-out ml-3"
                      />
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}

export default SortListHome
