import { useForm, Controller } from "react-hook-form"
import {
  NotifcationsData,
  NotificationModalProps,
} from "../../../types/notificationsTypes"
import useCustomMutation from "../../../hooks/useCustomMutation"
import {
  addNotification,
  editNotification,
} from "../../../services/notifications"
import { queryClient } from "../../../utils/reactQueryClient"
import { useEffect, useState } from "react"
import classNames from "classnames"
import Calendar from "react-calendar"
import "react-calendar/dist/Calendar.css"
import dayjs from "dayjs"
import "dayjs/locale/ar"
import "../../../style/CalendarStyles.css"
import { formatDateToISO } from "../../../utils/functions"
import { days, recurrenceType } from "../../../constants"

const NotificationModal: React.FC<NotificationModalProps> = ({
  notification,
  onClose,
}) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)
  const [isCalendarOpen2, setIsCalendarOpen2] = useState(false)
  const [repeatDays, setRepeatDays] = useState<string[]>(
    notification?.daysOfWeek?.split(",") || [],
  )

  const {
    register,
    handleSubmit,
    reset,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<NotifcationsData>()

  const { mutate: operateNotification } = useCustomMutation({
    mutationFn: notification ? editNotification : addNotification,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["notifications"] })
      onClose()
    },
  })

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value
    inputValue = inputValue.replace(/\D/g, "")

    if (inputValue.length > 2) {
      inputValue = inputValue.slice(0, 2) + "-" + inputValue.slice(2)
    }
    if (inputValue.length === 5) {
      const [day, month] = inputValue.split("-").map(Number)
      if (day > 31 || month > 12) {
        e.target.value = ""
        return
      }
    }

    e.target.value = inputValue
  }

  useEffect(() => {
    if (notification) {
      reset(notification)
    }
  }, [notification, reset])

  const onSubmit = (data: NotifcationsData) => {
    data.startDate = formatDateToISO(data.startDate)
    data.endDate = formatDateToISO(data.endDate)
    data.daysOfWeek = repeatDays?.toString()
    operateNotification(data)
  }

  useEffect(() => {
    register("message", { required: "هذا الحقل مطلوب" })
  }, [register])

  // useEffect(() => {
  //   setValue('daysOfMonth', '')
  //   setRepeatDays([])
  // }, [watch('recurrenceType')])

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white px-8 py-5 rounded-md w-[36rem] max-h-[90%] overflow-x-auto custom-scrollbar">
        <h2 className="h2">
          {notification ? "تعديل اشعار " : "إضافة اشعار جديد "}
        </h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-700"
            >
              عنوان الاشعار
            </label>
            <input
              {...register("title", { required: "هذا الحقل مطلوب" })}
              id="title"
              className={classNames("input", { "input-invalid": errors.title })}
              placeholder="العنوان"
            />
            {errors.title && (
              <p className="text-red-500 text-xs">{errors.title.message}</p>
            )}
          </div>
          <div className="mb-4 w-full flex items-center justify-between">
            <div className="w-[47%]">
              <label
                htmlFor="startDate"
                className="block text-sm font-medium text-gray-700"
              >
                تاريخ بداية الاشعار
              </label>
              <Controller
                name="startDate"
                control={control}
                rules={{ required: "هذا الحقل مطلوب" }}
                render={({ field }) => (
                  <div className="relative">
                    <input
                      {...field}
                      name="startTime"
                      className={classNames("input", {
                        "input-invalid": errors.startDate,
                      })}
                      placeholder="تاريخ بداية الاشعار"
                      value={
                        field.value
                          ? dayjs(field.value)
                              .locale("ar")
                              .format("dddd DD MMMM YYYY")
                          : ""
                      }
                      onClick={() => setIsCalendarOpen(true)}
                      readOnly
                    />
                    {isCalendarOpen && (
                      <div className="absolute z-10">
                        <Calendar
                          locale="ar"
                          maxDate={
                            watch("endDate")
                              ? new Date(watch("endDate"))
                              : undefined
                          }
                          onChange={(date) => {
                            field.onChange(date)
                            setIsCalendarOpen(false)
                          }}
                          value={field.value}
                        />
                      </div>
                    )}
                  </div>
                )}
              />
              {errors.startDate && (
                <p className="text-red-500 text-xs">
                  {errors.startDate.message}
                </p>
              )}
            </div>
            <div className="w-[47%]">
              <label
                htmlFor="startDate"
                className="block text-sm font-medium text-gray-700"
              >
                تاريخ نهاية الاشعار
              </label>
              <Controller
                name="endDate"
                control={control}
                rules={{ required: "هذا الحقل مطلوب" }}
                render={({ field }) => (
                  <div className="relative">
                    <input
                      {...field}
                      name="endTime"
                      className={classNames("input", {
                        "input-invalid": errors.endDate,
                      })}
                      placeholder="تاريخ نهاية الاشعار"
                      value={
                        field.value
                          ? dayjs(field.value)
                              .locale("ar")
                              .format("dddd DD MMMM YYYY")
                          : ""
                      }
                      onClick={() => setIsCalendarOpen2(true)}
                      readOnly
                    />
                    {isCalendarOpen2 && (
                      <div className="absolute z-10">
                        <Calendar
                          view="month"
                          minDate={
                            watch("startDate")
                              ? new Date(watch("startDate"))
                              : undefined
                          }
                          locale="ar"
                          onChange={(date) => {
                            field.onChange(date)
                            setIsCalendarOpen2(false)
                          }}
                          value={field.value}
                        />
                      </div>
                    )}
                  </div>
                )}
              />
              {errors.endDate && (
                <p className="text-red-500 text-xs">{errors.endDate.message}</p>
              )}
            </div>
          </div>
          <div className="mb-4 w-full flex items-center justify-between">
            <div className="w-[47%]">
              <label className="block text-sm font-medium text-gray-700">
                نوع الاشعار
              </label>
              <select
                {...register("recurrenceType", { required: "هذا الحقل مطلوب" })}
                className={classNames("input", {
                  "input-invalid": errors.recurrenceType,
                })}
                defaultValue="DAILY"
              >
                {recurrenceType.map((day, index) => (
                  <option key={index} value={day.value}>
                    {day.name}
                  </option>
                ))}
              </select>
              {errors.recurrenceType && (
                <p className="text-red-500 text-xs">
                  {errors.recurrenceType.message}
                </p>
              )}
            </div>
            <div className="w-[47%]">
              <label
                htmlFor="title"
                className="block text-sm font-medium text-gray-700"
              >
                وقت الاشعار
              </label>

              <input
                {...register("notificationTime", {
                  required: "هذا الحقل مطلوب",
                })}
                type="time"
                id="title"
                className={classNames("input", {
                  "input-invalid": errors.notificationTime,
                })}
                placeholder="وقت الاشعار"
              />
              {errors.notificationTime && (
                <p className="text-red-500 text-xs">
                  {errors.notificationTime.message}
                </p>
              )}
            </div>
          </div>
          {watch("recurrenceType") === "WEEKLY" && (
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                الأيام
              </label>
              <div className="flex flex-wrap">
                {days.map((day, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-center "
                  >
                    <button
                      type="button"
                      className={classNames(
                        "rounded-full mt-3 p-2 btn-add mr-2",
                        {
                          "bg-slate-500 text-white": repeatDays?.includes(
                            day?.value,
                          ),
                        },
                      )}
                      onClick={() =>
                        setRepeatDays((prev) =>
                          prev.includes(day.value)
                            ? prev.filter((d) => d !== day.value)
                            : [...prev, day.value],
                        )
                      }
                    >
                      {day.name}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
          {watch("recurrenceType") === "YEARLY" && (
            <div className="w-[47%] mb-4">
              <label className="block text-sm font-medium text-gray-700">
                يوم الاشعار
              </label>
              <input
                {...register("daysOfMonth", { required: "هذا الحقل مطلوب" })}
                type="text"
                onChange={handleDateChange}
                maxLength={5}
                placeholder="يوم / شهر"
                className={classNames("input", {
                  "input-invalid": errors.daysOfMonth,
                })}
              />
              {errors.daysOfMonth && (
                <p className="text-red-500 text-xs">
                  {errors.daysOfMonth.message}
                </p>
              )}
            </div>
          )}
          {watch("recurrenceType") === "MONTHLY" && (
            <div className="mb-4 w-[47%]">
              <label
                htmlFor="daysOfMonth"
                className="block text-sm font-medium text-gray-700"
              >
                يوم الاشعار
              </label>
              <input
                {...register("daysOfMonth", {
                  required: "هذا الحقل مطلوب",
                  min: {
                    value: 1,
                    message: "القيمة يجب أن تكون أكبر من أو تساوي 1",
                  },
                  max: {
                    value: 31,
                    message: "القيمة يجب أن تكون أقل من أو تساوي 31",
                  },
                })}
                type="number"
                max={31}
                min={1}
                id="daysOfMonth"
                className={classNames("input", {
                  "input-invalid": errors.daysOfMonth,
                })}
                placeholder="يوم الاشعار"
              />
              {errors.daysOfMonth && (
                <p className="text-red-500 text-xs">
                  {errors.daysOfMonth.message}
                </p>
              )}
            </div>
          )}
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              رسالة الاشعار
            </label>
            <textarea
              {...register("message", { required: "هذا الحقل مطلوب" })}
              className={classNames("input h-20", {
                "input-invalid": errors.message,
              })}
              placeholder="رسالة الاشعار"
            />
            {errors.message && (
              <p className="text-red-500 text-xs">{errors.message.message}</p>
            )}
          </div>
          <div className="flex justify-between pt-4">
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 transition duration-300"
            >
              حفظ
            </button>
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700 transition duration-300"
            >
              إلغاء
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default NotificationModal
