import dayjs from "dayjs"
import "dayjs/locale/ar"

export default dayjs

export const ParseDate = (
  date: string | number,
  format = "dddd DD MMMM YYYY",
) => dayjs(date).locale("ar").format(format)
export const ParseTime = (date: string | number, format = "DD-MM-YYYY") =>
  dayjs(date).locale("ar").format(format)
